$story-carousel: (
  "card-width": 200px,
  "card-height": 300px,
  "card-transition-duration": 800ms,
  "card-transition-easing": ease,
);
:root {
  // -----------------  Gradient Colors -------------------
  --gradient-1: linear-gradient(270deg, #27d4fa 0%, #7af4fc 100%);
  --gradient-2: linear-gradient(180deg, #222230 0%, #22222d 100%);
  --gradient-3: linear-gradient(
    126deg,
    rgba(233, 216, 129, 0.2) 20.3%,
    rgba(183, 229, 255, 0.05) 79.26%
  );

  --gradient-banner: linear-gradient(
    86deg,
    rgba(255, 255, 255, 0.08) 11.14%,
    rgba(255, 255, 255, 0.01) 113.29%
  );
  --gradient-hightight-section: linear-gradient(
    126deg,
    rgba(81, 181, 237, 0.2) 20.3%,
    rgba(255, 255, 255, 0.04) 79.26%
  );
  --gradient-mock: linear-gradient(
    90deg,
    rgba(34, 34, 46, 0.8) 0.02%,
    #22222d 51.43%,
    rgba(34, 34, 46, 0) 98.8%
  );
  --gradient-live-mock: linear-gradient(
    90deg,
    rgba(34, 34, 46, 0.8) 0.02%,
    #240c0c 51.43%,
    rgba(34, 34, 46, 0) 98.8%
  );

  // -----------------  dark Colors -------------------

  --dark-1: #000;
  --dark-2: #474752;
  --dark-3: #6b6a6e;
  --dark-4: #49e1fb;
  --dark-5: #7af4fc;
  --dark-6: #22222e;
  --dark-7: #22222f;

  // -----------------  light Colors -------------------

  --light-1: #c3c3c3;
  --light-2: #d3d3d6;
  --light-3: #96a7af;

  // -----------------  Tertiary Colors -------------------

  --tertiary-1: #f26d0c;
  --tertiary-2: #fdd649;
  --tertiary-25: #fdd64980;
  --tertiary-3: #3dd598;
  --tertiary-4: #ffab48;
  --tertiary-5: #ff575f;
  --tertiary-6: #ff0303;

  // -----------------  white Colors -------------------
  --white-100: #fff;
  --white-400: #ffffff66;
  --white-500: #ffffff80;
  --white-700: #ffffffb3;
  --white-900: #ffffffe6;
}
