/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

@import "../node_modules/primeicons/primeicons.css";

@import "ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";
@import ".././src/assets/scss/login.scss";
@import ".././src/assets/scss/variables.scss";

body {
  overflow-x: hidden;
}

/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;500;700;800;900&display=swap");
* {
  margin: 0;
  padding: 0;
  // font-family: 'Poppins', sans-serif;
}

body {
  background-color: #09070e;
}

body,
html {
  @include transition(all 0.5s);
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
}

body [class^="mat"],
body [class*=" mat-"],
body [class^="mdc"] {
  font-family: "Poppins", sans-serif !important;
}

body [class*="material-icons"] {
  font-family: "Material Icons Two Tone" !important;
}

/* mjx-container {
  font-size: 14px !important;
} */

/* [class^='mjx'],
[class^='MathJax'] {
    font-size: 14px !important;
} */

:root {
  // Primary color family

  --primary-blue: #31569a;
  --primary-Orange: #f26d0c;
  --jaffa: #f58838;
  --dark-blue: #0a1f44;

  // Secondary  color family

  --crimson: #e92c2c;
  --jade: #00ba34;
  --tangerine: #e19b00;
  --stratos: #00022b;

  // Monochrome  color family

  --slate-Gray: #6d7c8d;
  --mine-Shaft: #3a3a3a;
  --frosted-Mint: #d5fffd;
  --tara: #e0f7e7;
  --fair-Pink: #ffeaea;
  --sazerac: #fff4e1;
  --twilight-Blue: #eefbff;
  --sidenav-background: #bdbdbd;
}

/** Scroll Bar */

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 0;
}

/* Track */
::-webkit-scrollbar-track {
  background: #09070e;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #191924;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(180deg, #222230 0%, #22222d 100%);
}

/** Assessment */
.highlight {
  color: #ffab48;
}

.offer {
  // color: #fee98d;
  font-weight: 600;
  line-height: normal;
}

.see-more-btn {
  background: linear-gradient(270deg, #27d4fa 0%, #7af4fc 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-size: 14px;
  line-height: normal;
  text-transform: uppercase;
  padding-right: 0;
  font-family: "Poppins", sans-serif !important;
  border: 0;
  &.with-border {
    border: 1px solid #969696;
    padding: 10px;
  }
  &.icon-style {
    margin-left: 8px;
  }
}

.ef-lms-btn {
  border-radius: 9px;
  background: var(--ctabg, linear-gradient(270deg, #27d4fa 0%, #7af4fc 100%));
  // background: var(--ctabg, linear-gradient(270deg, #27D4FA 0%, #7AF4FC 100%));

  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  border: 0px;
  padding: 10px 20px;

  &:hover {
    color: initial;
  }

  &:disabled {
    color: #fff;
    border: 0;
    background: #22222f;

    &span {
      opacity: 0.4;
    }
  }

  &.lms-btn-link {
    /* background: linear-gradient(270deg, #27D4FA 0%, #7AF4FC 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text; */
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1rem;
    z-index: 1;
    color: #49e1fb;
    background: transparent;
  }

  &.lms-btn-link.danger {
    color: #fa2739;
  }
}

/* .view-details-bt{ background: noe !important; color: #49E1FB;} */
.no-gradient {
  color: #49e1fb;
  background: none !important;
  -webkit-background-clip: none !important;
  -webkit-text-fill-color: none !important;
  background-clip: none !important;
}

.lms-back-btn {
  color: #96a7af;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;

  display: flex;
  align-items: center;

  span {
    color: inherit;
  }
}

.modal-backdrop.show {
  opacity: 0.82;
}

.col-9 {
  .carousel-indicators {
    bottom: -8px;
  }
}

.lms-radio-toggle-btn {
  margin-right: 15px;

  label {
    border-radius: 12px;
    background: #22222f;

    color: #fff;
    text-align: center;
    font-weight: 700;
    line-height: 20px;
    padding: 10px 20px;

    span {
      white-space: nowrap;
      opacity: 0.4;
    }
  }

  .btn-check + .btn:hover {
    color: inherit;
    background-color: rgba($color: #fff, $alpha: 0.3);
    border-color: transparent;
  }

  .btn-check:checked + .btn {
    border: 1px solid #49e1fb;
    /*   background: linear-gradient(180deg, #22222F 0%, #22222F 100%), radial-gradient(circle at top right, #27D4FA, #7AF4FC);
        background-origin: border-box;
        background-clip: padding-box, border-box; */

    span {
      /*   background: linear-gradient(270deg, #27D4FA 0%, #7AF4FC 100%); */
      /* -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text; */
      color: #49e1fb;

      opacity: 1;
    }
  }
}

.comment-section {
  .form-control {
    border: 1px solid #535156;
  }
}

.lms-checked-toggle-btn {
  margin-right: 15px;

  label {
    border-radius: 12px;
    background: #22222f;

    color: #fff;
    text-align: center;
    font-weight: 500;
    line-height: 20px;
    padding: 10px 20px;

    span {
      white-space: nowrap;
      opacity: 0.4;
    }
  }

  .btn-check + .btn:hover {
    color: inherit;
    background-color: rgba($color: #fff, $alpha: 0.3);
    border-color: transparent;
  }

  .btn-check:checked + .btn {
    border: 1px solid transparent;
    background: linear-gradient(180deg, #22222f 0%, #22222f 100%),
      radial-gradient(circle at top right, #27d4fa, #7af4fc);
    background-origin: border-box;
    background-clip: padding-box, border-box;

    span {
      /*    background: linear-gradient(270deg, #27D4FA 0%, #7AF4FC 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text; */
      color: #49e1fb;
      opacity: 1;
    }
  }
}

.p-carousel {
  position: relative;

  &:hover {
    &::before {
      content: "";
      z-index: 1;
      position: absolute;
      left: 0px;
      top: 0px;
      height: 100%;
      display: none;
      width: 100px;
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 15%,
        rgba(99, 99, 99, 0) 55%
      );
    }

    &::after {
      content: "";
      z-index: 1;
      position: absolute;
      right: 0px;
      top: 0px;
      height: 100%;
      display: none;
      width: 100px;
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0) 52%,
        rgba(0, 0, 0, 1) 100%
      );
    }

    .backdropBackground {
      filter: blur(20px);
      opacity: 0.7 !important;
    }
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.82);
}

.no-gradient {
  color: #49e1fb;
  background: none !important;
  -webkit-background-clip: none !important;
  -webkit-text-fill-color: #49e1fb !important;
  background-clip: none !important;
}

.loginProfileLIst {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.loginProfileLIst li {
  width: 100%;
  text-align: left;
  padding-bottom: 8px;
}

.loginProfileLIst li button {
  margin: 0px;
  background: none;
  border: 0px;
  width: 100%;
  margin: 0px !important;
  text-align: left;
  color: #ebebeb;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.upgrade-style {
  color: #42dffb;
  background: #000;
  border-radius: 10px;
  padding: 3px 10px !important;
  font-size: 10px;
  margin-left: 10px;
}

.matmenustyle {
  overflow: inherit;
  border: 0px;
}

.profleBtn {
  border-radius: 4px;
  background: var(--CTA-BG, linear-gradient(270deg, #27d4fa 0%, #7af4fc 100%));
  color: #31313f;
  text-align: center;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 3px 10px;
  border-radius: 6px;
  margin-top: 6px;
  border: 0px;
}

.loginProfileLIst li button span {
  padding-left: 10px;
}

.no-text-gradientwith-bg {
  border-radius: 8px;
  background: #31313f !important;
}

.view-details-btn {
  -webkit-text-fill-color: #49e1fb !important;
}

.solution-btn {
  -webkit-text-fill-color: #49e1fb !important;
}

.cursorpointer {
  cursor: pointer;
}

.mat-mdc-form-field-infix {
  min-height: 30px !important;
}

.highlight-content .inner-html-tx div {
  padding: 0px 0px 10px 0px;
}

.mdc-dialog__actions {
  cursor: pointer;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(
    .mdc-text-field--textarea
  )
  .mat-mdc-form-field-infix {
  padding-top: 5px;
  padding-bottom: 3px;
}

// .product-banner-height {
//   height: 155px;
//   overflow: hidden;
// }

// .product-banner-height img {
//   width: 100%;
//   height: auto !important;
// }

// .sm-banner-banner-height {
//   height: 155px;
//   overflow: hidden;
// }

// .sm-banner-banner-height img {
//   max-width: 100%;
//   height: auto;
// }

.assessment-submit-modal .modal-container {
  border-radius: 16px;
  border-bottom: 0px;
}

.table thead tr th {
  border-left: 1px solid #000 !important;
  font-weight: 300 !important;
}

.table tbody tr td {
  border-left: 1px solid #000 !important;
}

.product-item-content hr {
  border-color: #cccccc80 !important;
  margin: 4px 0;
}

.successdatawrapper {
  text-align: center;
}

.status-tile-wrapper {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.status-tile-wrapper li {
  margin: 0px;
  padding: 0px;
  width: 50%;
  list-style: none;
  float: left;
}

/* .feedback-wrapper {
    padding-left: 40px;
} */

/* .app-insight-wrap {
  max-height: calc(100vh - 104px);
  overflow-y: auto;
} */
.hapters-block-scrollWrapper {
  max-height: calc(100vh - 236px);
  overflow-y: auto;
}
/* .go-to-back-wrap {
    position: fixed;
    top: 10px;
    left: 77px;
    z-index: 1;
} */

.product-name-wrap {
  position: fixed;
  top: 16px;
  left: 90px;
  z-index: 1;
}

.cursorpointer {
  cursor: pointer;
}

.text-right-align {
  text-align: right;
}

.invoice-btn-style {
  color: #27d4fa;
  font-size: 14px !important;
  background: none;
  border: 0px;
}

.invoice-btn-style span {
  margin-right: 7px;
  position: relative;
  top: 1px;
}

.invoice-loop:last-child .details-info-border {
  display: none;
}

.mat-drop-ui-style .mat-mdc-select-value {
  width: auto !important;
}

.mat-drop-ui-style
  .mat-mdc-form-field-type-mat-select
  .mat-mdc-select-arrow::after {
  right: 0px;
}

.mat-drop-ui-style .mat-mdc-select-arrow {
  margin-left: 10px;
}

.mat-drop-ui-style .mat-mdc-form-field-infix {
  width: auto !important;
}

.mat-drop-ui-style .mat-mdc-form-field {
  position: relative;
  top: -3px;
}

.mat-drop-ui-style .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mat-drop-ui-style .mat-mdc-form-field-type-mat-select .mat-mdc-select-arrow {
  position: relative;
  top: -1px;
}

.mat-custom-dropstyle.ef-select-box.mdc-menu-surface.mat-mdc-select-panel {
  min-width: 200px;
}

.mat-custom-dropstyle.mdc-menu-surface {
  right: 0px !important;
}

.mat-custom-dropstyle.mdc-menu-surface.mat-mdc-select-panel {
  position: absolute !important;
}

.mat-custom-dropstyle.ef-select-box.mdc-menu-surface.mat-mdc-select-panel {
  max-height: 200px !important;
  top: 10px;
}

.button {
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
  display: flex;
  padding: 12px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid;
  background: var(--BG, #09070e);
  &.primary {
    color: var(--dark-4);
    background: var(--dark-1);
    &:hover,
    &:visited {
      background: var(--gradient-1);
      color: var(--dark-1);
    }
  }
  &.secondary {
    background: var(--gradient-1);
    color: var(--dark-1);
  }
  &.inline-button {
    padding: 8px 12px;
    width: auto;
  }
  &.default {
    color: #96a7af;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    border: none;
    background: none;
  }
  &.theme-button {
    background: none;
    border: none;
    outline: none;
    color: var(--dark-4);
    width: auto;
    padding: 0;
  }
}
button {
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.tab-list {
  display: inline-flex;
  border-radius: 4px;
  .item {
    color: var(--white-100);
    font-size: 20px;
    font-weight: 300;
    line-height: normal;
    padding: 10px 30px;
    display: inline-block;
    cursor: pointer;
    border: 1px solid var(--dark-6);
    text-wrap: nowrap;
    &.active {
      color: var(--dark-4);
      background: var(--dark-6);
    }
  }
}
@media only screen and (max-width: 1600px) {
  .button {
    font-size: 14px;
    padding: 10px 8px;
  }
  .tab-list {
    .item {
      font-size: 16px;
      padding: 8px 24px;
    }
  }
}
@media only screen and (max-width: 1180px) {
  .getunlimited-ipad-header {
    display: block !important;
    position: relative;
  }

  .getunlimited-ipad-header .caption1 {
    padding-top: 3px;
  }

  .getunlimited-ipad-header .caption1,
  .getunlimited-ipad-header .caption2 {
    margin-left: 0 !important;
    font-size: 11px !important;
    display: block;
    width: 390px !important;
  }

  .selected-btn-ipad {
    position: absolute;
    right: 9px;
    top: 8px;
  }

  .side-content {
    padding-right: 14px !important;

    .learners-info {
      .social-icon {
        margin-left: 15px;

        img {
          width: 50px;
        }
      }

      .social-txt {
        font-size: 9px;
        line-height: normal;
        margin-left: 2px;
      }
    }
  }

  // .product-banner-height {
  //   height: 138px;
  // }

  .productName-title-ipad {
    -webkit-line-clamp: 2;
    margin-bottom: 10px !important;
    min-height: 42px;
    white-space: inherit !important;
  }

  .sub-caption-title-ipad {
    -webkit-line-clamp: 2;
    margin-bottom: 10px !important;
    min-height: 35px;
    margin: 0px;
    padding: 0px;
  }

  .product-item-content hr {
    margin-top: 0;
  }

  // .sm-banner-banner-height {
  //   height: 117px;
  // }
}

.star-conatiner {
  .star-template-conatiner {
    display: flex;
    flex-direction: column;
    align-items: center;

    .rating-star-icon {
      margin: 0 10px;
    }

    .rating-text {
      margin-top: 10px;
      color: #7a7a82;
      font-size: 11px;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.ef-lms-toggle-btn {
  white-space: nowrap;
}
@media only screen and (min-width: 1131px) {
  .closeBtnactionposition {
    display: none;
  }
}

@media only screen and (max-width: 1130px) {
  .scho-instruction-content {
    width: 100% !important;
  }
  .edit-header {
    font-size: 18px !important;
  }
  .instruct-head {
    font-size: 18px !important;
    font-weight: 400 !important;
  }
  .scho-subtitle {
    margin-top: 5px !important;
    margin-bottom: 16px !important;
  }
  .to-content .cntent-head-mob {
    opacity: 0.7 !important;
  }
  .activeBottomItemTop {
    display: none;
  }
  .isactiveBottomTop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: #000000cf !important;
    z-index: 5;
    transition: all 0.5s ease;
  }
  .isactiveBottomTop .neoJourneyWrapperContent {
    position: fixed;
    bottom: 0px;
    transition: 0.5s;
    width: 95%;
    background-color: #1c1827 !important;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.5) inset;
    border-radius: 16px;
    max-height: inherit;
    background-color: #1c1827 !important;
    z-index: 5;
    padding-right: 12px;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .isactiveBottomTop .topic-progress-container {
    height: auto !important;
    overflow: inherit !important;
  }
  .isactiveBottomTop .closeBtnactionposition {
    position: absolute;
    right: 0px;
    margin-top: -38px;
  }
  .isactiveBottomTop .topic-progress-section {
    background: none !important;
  }
  .unit-vertical-progress .StepProgress-item.current .s-bg {
    padding-right: 10px;
    left: -41px !important;
    margin-left: -16%;
    width: 139% !important;
  }
  .isactiveBottomTop .mobilecontentItemWrapper {
    max-height: calc(100vh - 200px);
    overflow: auto;
  }
  .isactiveBottomTop .mobilecontentItemWrapper::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  .isactiveBottomTop .topic-vertical-progress .StepProgress-item .lbl {
    margin-bottom: 0px !important;
    padding-bottom: 10px !important;
  }
}

@media only screen and (max-width: 500px) {
  .getunlimited-ipad-header {
    display: none !important;
    position: relative;
  }

  .padding-bottom-mb-0 {
    padding-bottom: 0 !important;
  }

  .row.reward-tiles.coin-tiles.mb-4.padding-bottom-mb-0 {
    margin-bottom: 13px !important;
  }

  .row.reward-tiles.mb-4.padding-bottom-mb-0 {
    margin-bottom: 13px !important;
  }

  .padding-bottom-mb-0 {
    padding-bottom: 0 !important;
  }

  // .sm-banner-banner-height {
  //   max-height: 117px;
  //   height: auto;
  // }
}

/*    .question-state-viewer-wrap .rect-box .status-icon{display: none;} */
/**   */

/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;800&display=swap");
:root {
  --card-width: 200px;
  --card-height: 300px;
  --card-transition-duration: 800ms;
  --card-transition-easing: ease;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.787);
  overflow: hidden;
}

button {
  border: none;
  background: none;
  cursor: pointer;
}
button:focus {
  outline: none;
  border: none;
}

.app {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -5;
  filter: blur(8px);
  pointer-events: none;
  user-select: none;
  overflow: hidden;
}
.app__bg::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 1;
  opacity: 0.8;
}
.app__bg__image {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) translateX(var(--image-translate-offset, 0));
  width: 180%;
  height: 180%;
  transition: transform 1000ms ease, opacity 1000ms ease;
  overflow: hidden;
}
.app__bg__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.app__bg__image.current--image {
  opacity: 1;
  --image-translate-offset: 0;
}
.app__bg__image.previous--image, .app__bg__image.next--image {
  opacity: 0;
}
.app__bg__image.previous--image {
  --image-translate-offset: -25%;
}
.app__bg__image.next--image {
  --image-translate-offset: 25%;
}

.cardList {
  position: absolute;
  width: calc(3 * var(--card-width));
  height: auto;
}
.cardList__btn {
  --btn-size: 35px;
  width: var(--btn-size);
  height: var(--btn-size);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
}
.cardList__btn.btn--left {
  left: -5%;
}
.cardList__btn.btn--right {
  right: -5%;
}
.cardList__btn .icon {
  width: 100%;
  height: 100%;
}
.cardList__btn .icon svg {
  width: 100%;
  height: 100%;
}
.cardList .cards__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  perspective: 1000px;
}

.card {
  --card-translateY-offset: 100vh;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) translateX(var(--card-translateX-offset)) translateY(var(--card-translateY-offset)) rotateY(var(--card-rotation-offset)) scale(var(--card-scale-offset));
  display: inline-block;
  width: var(--card-width);
  height: var(--card-height);
  transition: transform var(--card-transition-duration) var(--card-transition-easing);
  user-select: none;
}
.card::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 1;
  transition: opacity var(--card-transition-duration) var(--card-transition-easing);
  opacity: calc(1 - var(--opacity));
}
.card__image {
  position: relative;
  width: 100%;
  height: 100%;
}
.card__image img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card.current--card {
  --current-card-rotation-offset: 0;
  --card-translateX-offset: 0;
  --card-rotation-offset: var(--current-card-rotation-offset);
  --card-scale-offset: 1.2;
  --opacity: 0.8;
}
.card.previous--card {
  --card-translateX-offset: calc(-1 * var(--card-width) * 1.1);
  --card-rotation-offset: 25deg;
}
.card.next--card {
  --card-translateX-offset: calc(var(--card-width) * 1.1);
  --card-rotation-offset: -25deg;
}
.card.previous--card, .card.next--card {
  --card-scale-offset: 0.9;
  --opacity: 0.4;
}

.infoList {
  position: absolute;
  width: calc(3 * var(--card-width));
  height: var(--card-height);
  pointer-events: none;
}
.infoList .info__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  perspective: 1000px;
  transform-style: preserve-3d;
}

.info {
  margin-bottom: calc(var(--card-height) / 8);
  margin-left: calc(var(--card-width) / 1.5);
  transform: translateZ(2rem);
  transition: transform var(--card-transition-duration) var(--card-transition-easing);
}
.info .text {
  position: relative;
  font-family: "Montserrat";
  font-size: calc(var(--card-width) * var(--text-size-offset, 0.2));
  white-space: nowrap;
  color: #fff;
  width: fit-content;
}
.info .name,
.info .location {
  text-transform: uppercase;
}
.info .location {
  font-weight: 800;
}
.info .location {
  --mg-left: 40px;
  --text-size-offset: 0.12;
  font-weight: 600;
  margin-left: var(--mg-left);
  margin-bottom: calc(var(--mg-left) / 2);
  padding-bottom: 0.8rem;
}
.info .location::before, .info .location::after {
  content: "";
  position: absolute;
  background: #fff;
  left: 0%;
  transform: translate(calc(-1 * var(--mg-left)), -50%);
}
.info .location::before {
  top: 50%;
  width: 20px;
  height: 5px;
}
.info .location::after {
  bottom: 0;
  width: 60px;
  height: 2px;
}
.info .description {
  --text-size-offset: 0.065;
  font-weight: 500;
}
.info.current--info {
  opacity: 1;
  display: block;
}
.info.previous--info, .info.next--info {
  opacity: 0;
  display: none;
}

.loading__wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #000;
  z-index: 200;
}
.loading__wrapper .loader--text {
  color: #fff;
  font-family: "Montserrat";
  font-weight: 500;
  margin-bottom: 1.4rem;
}
.loading__wrapper .loader {
  position: relative;
  width: 200px;
  height: 2px;
  background: rgba(255, 255, 255, 0.25);
}
.loading__wrapper .loader span {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: red;
  transform: scaleX(0);
  transform-origin: left;
}

@media only screen and (min-width: 800px) {
  :root {
    --card-width: 250px;
    --card-height: 400px;
  }
}
.support {
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 10px;
  display: flex;
}
.support a {
  margin: 0 10px;
  color: #fff;
  font-size: 1.8rem;
  backface-visibility: hidden;
  transition: all 150ms ease;
}
.support a:hover {
  transform: scale(1.1);
}

 */
.profileNameinfocolor {
  color: #96a7af;
}

@media only screen and (max-width: 768px) {
  .padding-box-around {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .profileNameinfocolor {
    color: #96a7af;
    margin-top: 0.1rem !important;
  }

  .subscriptionwrap {
    padding-right: 13px;
  }

  .padding-left-right-profile {
    padding-left: 17px;
    padding-right: 17px;
  }

  .p-carousel:hover::before {
    display: none !important;
  }

  .p-carousel:hover::after {
    display: none !important;
  }

  .eflms-carousel .carousel .carousel-indicators {
    margin-bottom: -16px !important;
  }

  .post-login-home-mobile .testimonials .testimonial-card {
    margin-bottom: 0px !important;
  }

  .post-login-home-mobile .blog-section .blog-card {
    margin-bottom: 0px !important;
  }
}

.checkhistorybtn {
  min-width: 120px;
}

@media only screen and (max-width: 1150px) {
  .neoJourneyBottomSheetMidContent {
    max-height: calc(100vh - 400px);
    overflow-y: auto;
  }
  .strongArea {
    margin-bottom: 30px;
  }
  .strongArea img {
    position: relative;
    top: -2px;
  }
  .strongArea span {
    color: #fff;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.5;
    margin-left: 10px;
  }
  .text-redeem-wrap-width {
    max-width: 167px;
  }

  .checkhistorybtn {
    min-width: 120px;
  }
}

@media only screen and (max-width: 992px) {
  .arrow-align-mb {
    position: relative;
    top: -3px;
  }

  .text-redeem-wrap-width {
    max-width: inherit;
  }

  .coinandreferraliconstyle {
    width: 24px !important;
    height: 24px !important;
  }

  .mat-mdc-form-field-infix {
    // max-width: 100px; don't uncommented without aamir
  }

  /*  .cdk-overlay-pane{ width: 200px !important;} */
  .mat-mdc-select-trigger {
    min-width: auto; // dont change without call aamir
    max-width: 160px;
    //  min-width: 149px; don't uncommented without aamir
  }
}

@media only screen and (max-width: 550px) {
  .text-redeem-wrap-width {
    max-width: 184px;
  }

  .bill-details-section-mobile {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .bill-details-section-mobile .product-price {
    min-width: 30%;
    margin-left: 19px;
    text-align: right;
  }

  .assessment-payment-mobile-sm {
    padding-bottom: 130px !important;
  }

  .lms-subject-insights-mobile {
    padding-bottom: 100px;
  }

  .list-inline.rating-list {
    position: relative;
  }

  .rating-list {
    display: inline-block;
    list-style: none;
    width: 62px;
  }

  .rating-list span.NotSatisfiedtx {
    font-size: 9px !important;
    position: absolute;
    left: 69px;
    top: 46px;
    width: 100px;
    text-align: center;
    display: inline-block;
  }

  .rating-list span.greatTx {
    font-size: 9px !important;
    position: absolute;
    right: 70px;
    top: 46px;
    width: 100px;
    display: inline-block;
    text-align: center;
  }

  .tab-list {
    width: 100%;
    overflow: auto;
    .item {
      font-size: 14px;
      padding: 6px 12px;
    }
  }
}

.modal-content {
  background-color: #1c1827;
  border-radius: 16px;
}

.topictogglebtnWrappper {
  margin-top: 29px;
  margin-bottom: 29px !important;
}

.topictogglebtnWrappper .btn.btn-dark {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-right: 12px !important;
  margin-left: 2px !important;
  white-space: nowrap;
}

@media only screen and (max-width: 768px) {
  .testproductdetalswrap.post-login-assessment-landing
    .multi-screen-carousel
    .p-carousel
    .p-carousel-content
    .p-carousel-container
    .product-item
    .product-item-content
    .tiles
    .caption {
    height: auto;
    padding-bottom: 6px;
  }

  // .testproductdetalswrap.post-login-assessment-landing
  //   .sm-banner-banner-height {
  //   height: auto;
  // }

  .testproductdetalswrap.post-login-assessment-landing
    .multi-screen-carousel
    .p-carousel
    .p-carousel-content
    .p-carousel-container
    .product-item
    .product-item-content
    .tiles
    .caption {
    height: auto !important;
    padding-bottom: 6px;
  }

  .testproductdetalswrap.post-login-assessment-landing .latest-price.m-pricing {
    padding-top: 0px !important;
  }

  .product-text-wrap {
    max-width: 60%;
  }

  .product-text-wrap-todo {
    max-width: 90%;
  }

  .profilenavtop {
    border-top: 1px solid #cccccc20;
    padding-top: 10px;
  }

  .question-state-bottom-panel-wrapper .cdk-dialog-container {
    background-color: #1c1827 !important;
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.5) inset;
    border-radius: 16px;
    overflow: inherit;
    // padding-left:21px; padding-right:21px;
  }

  .voucher-dialog::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
  }

  .question-state-bottom-panel-wrapper .closeBtnaction {
    position: absolute;
    right: 16px;
    margin-top: -44px !important;
  }

  .question-state-bottom-panel-wrapper .plan-details .mat-mdc-button-base {
    margin-top: 20px;
  }

  .question-state-bottom-panel-wrapper .container-fluid.voucher-dialog {
    max-height: calc(100vh - 160px);
    overflow-y: auto;
  }

  .question-state-bottom-panel-wrapper
    .mat-bottom-sheet-container
    .save-button {
    background: linear-gradient(270deg, #27d4fa 0%, #7af4fc 100%);
    color: #31313f;
    font-size: 14px;
    font-weight: 700;
    border-radius: 8px;
    height: 45px;
    width: 75% !important;
    border: 0px;
  }

  .question-state-bottom-panel-wrapper .mat-bottom-sheet-container .sub-header {
    font-size: 12px;
    color: #e2e2e2;
    margin-top: 5px;
  }

  .question-state-bottom-panel-wrapper .plan-details {
    border-radius: 16px;
    background: linear-gradient(180deg, #222230 0%, #22222d 100%);
    padding: 15px;
    color: #ffffff;
    min-height: 250px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .question-state-bottom-panel-wrapper
    .mat-bottom-sheet-container
    .edit-header {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #ffffff;
  }

  .question-state-bottom-panel-wrapper
    .mat-bottom-sheet-container
    .save-button:disabled {
    background: #94939b;
  }

  .question-state-bottom-panel-wrapper .plan-details {
    font-size: 12px;
    padding: 0px !important;
    min-height: 204px;
    padding: 12px 15px !important;
  }

  .question-state-bottom-panel-wrapper .container-fluid.rating-container {
    max-height: calc(100vh - 160px);
    overflow-y: auto;
  }

  .mdc-menu-surface.mat-mdc-select-panel {
    min-width: 200px;
  }

  .close-icon-mat-box {
    right: 16px;
  }
}

@include media-breakpoint-up(lg) {
  .comment-section {
    display: flex;
    justify-content: center;

    .form-control {
      width: 370px;
    }
  }
}

.progress-marker-wrapper {
  position: relative;
}

.progress-marker-wrapper::after {
  display: none;
}

.progress-marker-wrapper::before {
  content: "";
  width: 0px !important;
  height: 0px !important;
  position: absolute !important;
  border-left: 6px solid #fff !important;
  border-right: 23px solid transparent !important;
  border-top: 17px solid #fff !important;
  border-bottom: 9px solid transparent;
  left: 9px !important;
  bottom: -9px;
  border-radius: 16px !important;
}

.q-mobile-footer a {
  color: #49e1fb !important;
}

.commonBtnStyle {
  font-size: 14px;
  color: #31313f;
  font-weight: 700;
  background: linear-gradient(270deg, #27d4fa 0%, #7af4fc 100%);
  border: 0;
  border-radius: 9px;
  height: 38px;
  display: inline-block;
  min-width: 179px;
  position: relative;
  padding-right: 20px !important;
}

.btn-right-arrow-position {
  position: absolute;
  right: 10px;
  top: 13px;
}

.commonBtnStyle:disabled {
  background: #94939b;
}

.commonBtnStyle:hover,
.commonBtnStyle:focus {
  color: #31313f;
}

.crossAction {
  z-index: 4000;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 12px;
}

.finish-btn-right {
  margin-right: 8px !important;
}

.ef-disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
  margin-right: 8px !important;
}
.opacity-07 {
  opacity: 0.7;
}
.disabled-button {
  opacity: 0.5;
  cursor: default;
}

@media only screen and (min-width: 1500px) {
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 90%;
  }
}
